import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import UserProfile from '../scene/Profile/Profile';

import Home from '../scene/Home.js';
import OrderList from '../scene/orderList/orderList';
import { Helmet } from 'react-helmet';
import { Component } from 'react';
import Login from '../scene/login';
import Login_tg from '../scene/login_tg';
import InventoryEdit from '../scene/InventoryEdit';
import ProductEdit from '../scene/Product/ProductEdit';

import Matches from '../scene/Matches/Matches';
import BetRecords from '../scene/BetRecords/BetRecords';
import PlayerRank from '../scene/PlayerRank/PlayerRank';


import { useLocation } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CodeRedeem from '../scene/Redeem/Redeem';


import {
  faCashRegister,
  faCheck,
  faMinusSquare,
  faPenSquare,
  faQuestionCircle,
  faStore,
  faUnlock,
  faUserClock, 
  faMoneyBill,
  faFutbol,
  faCat

  
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";


let pack = require('../../package.json');
const GameMasterRestAPI = require('../service/GameMasterRestAPI.js').default;

const Header = () => {
  const location = useLocation();
  const [api_version, setAPIVersion] = React.useState('?');
  const [user_email, setUserEmail] = React.useState('');
  const [user_balance, setUserBalance]= React.useState('');
  const [userID, setUserID]= React.useState('');
  const [betPath, setBetPath]= React.useState('');

  React.useEffect(async () => {
    const { data } = await GameMasterRestAPI.getVersion();
    console.info('----');
    console.info(data);
    // this.setState({ api_version: data });
    setAPIVersion(data);
  }, []);
  React.useEffect(async () => {
    let betpath2="/BetRecords/"+userID;
    setBetPath(betpath2);

    console.log(window.location.href);
    if (window.location.href.indexOf('/login') == -1) {
      GameMasterRestAPI.login().then((r) => {

        console.log(r);
        try {
        const {
          user: { email,  balance, _id },
        } = r;
        // this.setState({ user_email: email });

        console.log(r.user._id);
        
        setUserEmail(email);
        setUserBalance(
          parseFloat(balance).toFixed(1));
        setUserID(r.user._id);
        } catch (e){
          console.log(e);
          setUserEmail("");
          setUserBalance(0);
          setUserID(0);
  
        }


      });
    }
  }, [location]);

  // constructor(props) {
  //   super(props);
  //   // this.state = {
  //   //   api_version: "?",
  //   //   user_email: ""
  //   // };

  //   // GameMasterRestAPI.getVersion().then((data) => {
  //   //   console.info("----");
  //   //   console.info(data.data);
  //   //   this.setState({ api_version: data.data });
  //   // });
  // }

  // componentDidMount() {
  //   this.timerID = setInterval(
  //     () => this.tick(),
  //     1000
  //   );
  // }
  // componentWillUnmount() {
  //   clearInterval(this.timerID);
  // }
  // tick() {
  //   if (window.location.href.indexOf("/login") == -1) {
  //     GameMasterRestAPI.login().then(r => {
  //       const { user: { email } } = r;
  //       this.setState({ user_email: email });
  //     })
  //   }
  // }

  const handleLogout = async () => {
    await GameMasterRestAPI.logout();
    // localStorage.getItem("auth");
    window.location.href = '/login?logout=true';
  };

  // render() {


  return (
    <div>
      <Helmet>
        <title>Gamer - Service Platform</title>
        <link
          rel='icon'
          href='/HK24.png'
          type='image/png'
        />
      </Helmet>
      <BrowserRouter>
        <Navbar
          sticky='top'
          collapseOnSelect
          expand='lg'
          bg='dark'
          variant='dark'
          width='100%'>
          <Navbar.Brand href='/'>
            {' '}
            <img
              alt='Logo'
              width='50px'
              src='/HK24.png'
            />{' '}
            <span>HK24 Game-Master</span>{' '}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='responsive-nav-bar-nav' />
          <Navbar.Collapse id='responsive-nav-bar-nav'>
            <Nav className='mr-auto'>
              <NavDropdown title='Actions'>
                <NavDropdown.Header>Actions:</NavDropdown.Header>

                <NavDropdown.Item href='/Matches'>
                  Matches
                </NavDropdown.Item>


              </NavDropdown>
              <NavDropdown title='Records'>
                <NavDropdown.Header>Actions:</NavDropdown.Header>

                <NavDropdown.Item href={'/BetRecords/'} >
                  Back Records
                </NavDropdown.Item>
                <NavDropdown.Item href='/CodeRedeem'>
                  Code Redeem
                </NavDropdown.Item>
                <NavDropdown.Item href='/PlayerRank'>
                  Player Ranking
                </NavDropdown.Item>

                
              </NavDropdown>

              <NavDropdown title='Users'>
                {localStorage.getItem('auth') && (
                <NavDropdown.Item href='/UserProfile'>
                  User Profile
                </NavDropdown.Item>
                )}
                {localStorage.getItem('auth') && (
                  <NavDropdown.Item onClick={() => handleLogout()}>
                    Logout
                  </NavDropdown.Item>
                )}
                {!localStorage.getItem('auth') && (
                  <NavDropdown.Item href='/login'>Login</NavDropdown.Item>
                )}
              </NavDropdown>
            </Nav>
            <Navbar.Brand className='float-right' inline='true' >
            <b>
              🇭🇰 HK24
              &nbsp;
              
              <FontAwesomeIcon
                size="lg"
                icon={faFutbol}
                style={{  }}
              />
              &nbsp;
              
              {user_balance}
              
              </b>

              &nbsp;&nbsp;&nbsp;


              {user_email}


              <div>
              <small>




                <font color='white'>
                  <small>
                  Api-{api_version} | {pack.name} - {pack.version} -
                  {process.env.REACT_APP_BUILD_TIME} | By                   <FontAwesomeIcon
                size="sm"
                icon={faCat}
                style={{  }}
              /> &nbsp;
              void Neko();

                  
                  </small>
                </font>
              </small>
              </div>
            </Navbar.Brand>
          </Navbar.Collapse>
        </Navbar>

        <Switch>
          <Route exact path='/' component={Home} />


          <Route path='/UserProfile/' component={UserProfile} />


          <Route path='/BetRecords/' uid={userID} component={BetRecords}  />
          <Route path='/Matches/' component={Matches} />
          
          <Route path='/login' component={Login} />

          <Route path='/login_tg' component={Login_tg} />

          <Route path='/CodeRedeem' component={CodeRedeem} />

          <Route path='/PlayerRank' component={PlayerRank} />
          
          


          
        </Switch>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
  // }
};

export default Header;

/*
                <NavDropdown.Item href='/ProductInput'>
                  Product Input
                </NavDropdown.Item>

                <NavDropdown.Item href='/InventoryInput'>
                  Inventory Input
                </NavDropdown.Item>

                <NavDropdown.Item href='/OrderNote'>
                  Create Order Note
                </NavDropdown.Item>

                <NavDropdown.Item href='/RentalNote'>
                  Create Rental Note
                </NavDropdown.Item>

                <NavDropdown.Item href='/SaleNote'>
                  Create Sale Note
                </NavDropdown.Item>

                <NavDropdown.Item href='/DepositReturn' disabled>
                  Return Deposit
                </NavDropdown.Item>


                <NavDropdown.Item href='/Price'>Price</NavDropdown.Item>
                <NavDropdown.Item href='/ProductList'>
                  Product List
                </NavDropdown.Item>
                <NavDropdown.Item href='/Inventory'>
                  Inventory List
                </NavDropdown.Item>
                <NavDropdown.Item href='/OrderNoteList'>
                  Order Note List
                </NavDropdown.Item>
                <NavDropdown.Item href='/RentalNoteList'>
                  Rental Note List
                </NavDropdown.Item>
                <NavDropdown.Item href='/InventoryCheckio'>
                  InventoryCheckio
                </NavDropdown.Item>
                <NavDropdown.Item href='/SaleNoteList'>
                  Sale Note List
                </NavDropdown.Item>

                <NavDropdown.Divider />

                // -------------------------
          <Route path='/Price' component={PriceList} />

          <Route path='/ProductInput' component={ProductInput} />
          <Route path='/ProductList' component={ProductList} />
          <Route path='/ProductEdit/:id' component={ProductEdit} />
          <Route path='/OrderNote' component={OrderNote} />
          <Route path='/OrderNoteList' component={OrderNoteList} />
          <Route path='/OrderNoteEdit/:id' component={OrderNoteEdit} />

          <Route path='/SaleNote' component={SaleNote} />

          <Route path='/InventoryInput' component={InventoryInput} />
          <Route path='/Inventory' component={InventoryList} />
          <Route path='/InventoryEdit/:id' component={InventoryEdit} />

          

          <Route path='/RentalNote' component={OrderInput} />
          <Route path='/RentalNoteList' component={RentalNoteList} />
          <Route path='/RentalNoteEdit/:id' component={RentalNoteEdit} />
          <Route path='/InventoryCheckio' component={InventoryCheckio} />
          <Route path='/SaleNoteList' component={SaleNoteList} />
          <Route path='/SaleNoteEdit/:id' component={SalesNoteEdit} />                

*/