import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DataTable from "react-data-table-component";

const GameMasterRestAPI = require("../../service/GameMasterRestAPI.js").default;



class PlayerRank extends Component {
    constructor(props) {
      super(props);
      this.page = 1;
      this.uid=props.uid;

      this.state={
        mapData: {},
        loading: false,
      }
      this.getPlayerInfo();
    
    }

    /*
          {
        name: "Player",
        selector: "_id",
        sortable: true,
        right: true,
      },

    */
    columns = [
      {
        name: "Position",
        selector: "_id",
        sortable: true,
        right: true,
        format: (row, index) => {
              return index;
        }
      },
      {
        name: "Name",
        selector: "user.first_name",
        sortable: true,
        right: true,
        format: (row, index) => {
          if (row.first_name)
              return row.first_name;
            else 
              return row.email;
        }
      },
      {
        name: "Score",
        selector: "balance",
        sortable: true,
        right: false,
        format: (row, index) => {
          return parseFloat(row.balance).toFixed(2);
        }
      },
    ]
    
    getPlayerInfo = ()=>{
      GameMasterRestAPI.getData({
        service: "users",
        sort: {
          balance: -1
        },

        query_parameter: {
        }
      }).then((data) => {
        console.info(data.data);
        this.setState({
          mapData: data,
          total: data.total,
          loading: false,
          //perPage: data.meta.pagination.pageSize,
        });
      });
    }
    

render() {
  return  <>
  <div>
    <DataTable
      columns={this.columns}
      data={this.state.mapData.data}
      pagination
      striped
      highlightOnHover
      paginationServer
      onChangeRowsPerPage={this.handlePerRowsChange}
      onChangePage={this.handlePageChange}
      sortServer
      onSort={this.handleOnSort}
      paginationPerPage={200}
      paginationRowsPerPageOptions={[25, 50, 100, 150,200]}
    />    
  </div>
  </>
  
};

}

PlayerRank.propTypes = {};

PlayerRank.defaultProps = {};

export default PlayerRank;
