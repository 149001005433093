import React from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  FormControl,
  Alert,
} from "react-bootstrap";
import {
  faCoffee,
  faCircle,
  faSync,
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const GameMasterRestAPI = require("../../service/GameMasterRestAPI.js").default;

const ProductEdit = (params) => {
  const { id } = useParams();
  React.useEffect(() => {
    GameMasterRestAPI.getDataByID({
      id,
      service: "product",
    }).then((data) => {
      console.info("====== Product ======");
      console.info(data);
      const {
        ProductID,
        ProductName,
        Metal,
        desc,
        weight,
        weighttael,
        rentalCost,
        Vendor,
        width,
        lengthcm,
      } = data;
      const fields = {
        ProductID,
        ProductName,
        Metal,
        desc,
        weight,
        weighttael,
        rentalCost,
        Vendor,
        width,
        lengthcm,
      };
      Object.keys(fields).forEach((key) => {
        setValue(key, fields[key]);
      });
    });
  }, [id]);

  const { register, setValue, watch, handleSubmit } = useForm();
  const [submitStatus, setSubmitStatus] = React.useState({
    icon: faCircle,
    className: "",
    color: "",
  });

  const convertSubmitStatus = (status) => {
    let { icon, className, color } = {
      icon: faCircle,
      className: "",
      color: "",
    };
    switch (status) {
      case "init":
        icon = faCircle;
        break;
      case "ing":
        icon = faSync;
        className = "fa-spin";
        break;
      case "done":
        icon = faCheckCircle;
        color = "green";
        break;
      case "error":
        icon = faExclamationTriangle;
        color = "red";
        break;
      default:
        icon = faCircle;
        break;
    }
    return { icon, className, color };
  };

  const weightValue = watch("weight", 0);

  const [errorMessage, setErrorMessage] = React.useState(null);

  const onSubmit = async (data) => {
    setSubmitStatus(convertSubmitStatus("ing"));
    GameMasterRestAPI.patch({ id, service: "product", data })
      .then((d) => {
        console.log(d);
        setSubmitStatus(convertSubmitStatus("done"));
      })
      .catch((d) => {
        console.log(d);
        setErrorMessage(d.message);
        setSubmitStatus(convertSubmitStatus("error"));
      });
  };

  return (
    <>
      <Container>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs={2}>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="ProductID">ProductID</InputGroup.Text>
                </InputGroup.Prepend>
              </InputGroup>
              <InputGroup hasValidation>
                <FormControl
                  placeholder="ProductID"
                  aria-label="ProductID"
                  aria-describedby="ProductID"
                  readOnly
                  {...register("ProductID", { required: true })}
                />
              </InputGroup>
            </Col>

            <Col xs={2}>
              <InputGroup>
                <FormControl
                  placeholder="ProductName"
                  aria-label="ProductName"
                  aria-describedby="ProductName"
                  {...register("ProductName")}
                />
              </InputGroup>
              <InputGroup>
                <FormControl
                  placeholder="Metal"
                  aria-label="Metal"
                  aria-describedby="Metal"
                  {...register("Metal")}
                />
              </InputGroup>
            </Col>

            <Col xs={2}>
              <InputGroup>
                <FormControl
                  placeholder="Description"
                  aria-label="Weight"
                  aria-describedby="basic-addon2"
                  as="textarea"
                  rows={3}
                  {...register("desc")}
                />
              </InputGroup>
            </Col>

            <Col xs={2}>
              <InputGroup>
                <FormControl
                  placeholder="Reference Weight"
                  aria-label="Reference Weight"
                  aria-describedby="basic-addon2"
                  type="number"
                  step="0.001"
                  onInput={(e) => {
                    setValue(
                      "weighttael",
                      (e.target.value / 37.429).toFixed(3)
                    );
                  }}
                  {...register("weight", { required: true })}
                />
                <InputGroup.Append>
                  <InputGroup.Text id="basic-addon2">Gram</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
              <InputGroup>
                <FormControl
                  placeholder="Weight (Tael)"
                  aria-label="weighttael"
                  aria-describedby="weighttael"
                  // readOnly
                  type="number"
                  step="0.001"
                  onInput={(e) => {
                    setValue("weight", (e.target.value * 37.429).toFixed(3));
                  }}
                  {...register("weighttael", { disabled: true })}
                />

                <InputGroup.Append>
                  <InputGroup.Text id="basic-addon2">Tael</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Col>

            <Col xs={2}>
              <InputGroup.Prepend>
                <InputGroup.Text id="rentalCost">Rental Cost</InputGroup.Text>
              </InputGroup.Prepend>
              <InputGroup>
                <FormControl
                  placeholder="Rental Cost"
                  aria-label="rentalCost"
                  aria-describedby="rentalCost"
                  {...register("rentalCost")}
                  required
                />
              </InputGroup>
            </Col>
            <Col xs={1}>
              <FontAwesomeIcon
                icon={submitStatus.icon}
                className={submitStatus.className}
                color={submitStatus.color}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={2}>
              <InputGroup>
                <FormControl
                  placeholder="Vendor"
                  aria-label="Vendor"
                  aria-describedby="vendor"
                  {...register("Vendor")}
                />
              </InputGroup>
            </Col>
            <Col xs={2}>
              <FormControl
                placeholder="Width (cm)"
                aria-label="width"
                aria-describedby="width"
                {...register("width")}
              />
            </Col>
            <Col xs={2}>
              <FormControl
                placeholder="Length (cm)"
                aria-label="lengthcm"
                aria-describedby="lengthcm"
                {...register("lengthcm")}
              />
            </Col>

            <Col xs={6}>
              <button type="submit">Submit</button>

              <FontAwesomeIcon
                icon={submitStatus.icon}
                className={submitStatus.className}
                color={submitStatus.color}
              />
            </Col>

            {errorMessage && (
              <Col xs={12}>
                <Alert variant="danger">{errorMessage}</Alert>
              </Col>
            )}
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default ProductEdit;
