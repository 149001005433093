import {
  faCashRegister,
  faCheck,
  faMinusSquare,
  faPenSquare,
  faQuestionCircle,
  faStore,
  faUnlock,
  faUserClock,
  faMoneyBill,
  faFutbol,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment-timezone";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { useParams } from "react-router";



const GameMasterRestAPI = require("../../service/GameMasterRestAPI.js").default;

const MatchMasterRestAPI =
  require("../../service/MatchMasterRestAPI.js").default;


  class BetRecords extends Component {
  
    constructor(props) {
      super(props);
      this.page = 1;
      this.uid=props.uid;
      console.log("props");
      console.log(props);


      GameMasterRestAPI.getLoginInfo().then((user)=>{
//        console.log("user login info")
        this.uid=user.user._id;
      });

    }



    columns = [
/*      {
        name: "ID",
        selector: "_id",
        sortable: true,
      },
*/
{
  name: "Owner",
  selector: "_id",
  sortable: true,
  maxWidth: "150px",
  format: (row, index) => {
//    console.log(row);
    var uname = row.owner;
    
    if (row.user && row.user.first_name)
      uname = row.user.first_name;
    if (row.user && !row.user.first_name)
      uname = row.user.email;

//      console.log(row.user);

//      console.log(uname);
    return (
      /*
      row.attributes.teamA.data.attributes.flag +
      row.attributes.teamA.data.attributes.name
    */
uname  );
  },
},

{
  name: "Match Name",
  selector: "match_info.data.attributes.matchName",
  sortable: true,
  maxWidth: "300px",
},

      {
        name: "Backed Side",
        selector: "back",
        sortable: true,
        maxWidth: "200px",
        format: (row, index) => {

          var backname="Draw";

          if (row.back=="Home"){
            backname=row.match_info.data.attributes.teamA.data.attributes.name + " "
            + row.match_info.data.attributes.teamA.data.attributes.flag + " "
          }
          if (row.back=="Away"){
            backname=row.match_info.data.attributes.teamB.data.attributes.name + " "
            + row.match_info.data.attributes.teamB.data.attributes.flag + " "
            ;
          }

          //console.log(row);
          return (
                 backname
            );
        },
      
      },

      {
        name: "Stake",
        selector: "stake",
        sortable: true,
        maxWidth: "120px",
      },
  
      {
        name: "Payout Rate",
        selector: "payout",
        sortable: true,
        maxWidth: "120px",
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        maxWidth: "80px",
      },
  
    ];
  
    state = {
      mapData: {},
      loading: false,
      totalRows: 0,
      perPage: 200,
      sortDirection: 1, // or -1 (1 is ascending, -1 is descending)
      sortSelector: null,
      searchText: "",
      onlyMe: false
    };
  
    handleOnSort = (column, sortDirection) => {
      // https://github.com/jbetancur/react-data-table-component#sorting
      const { selector } = column;
  
      this.setState(
        {
          sortDirection: sortDirection === "asc" ? 1 : -1, // assume there are only asc and desc
          sortSelector: selector,
        },
        () => this.getBetData()
      );
    };
  
    handlePageChange = (page) => {
      if (page === -1) {
        page = this.page;
      }
      this.page = page;
      this.getBetData(page);
    };
    handlePerRowsChange = (perPage, page) => {
      this.setState(
        {
          perPage: perPage,
          currentPage: page,
        },
        () => {
          this.getBetData(page);
        }
      ); // we don't call setState to avoid double call
    };
  
    getBetData = (page = this.page) => {
      console.info(this.state);
      console.info(page);
    
      const { perPage, sortSelector, sortDirection } = this.state;
      let show_page = page ? page : 1;
  
      let skip = (show_page - 1) * perPage;
      //console.info(skip);
  
      this.setState({ loading: true });

      console.info("= = = v v v");

      let qp={
        
      };
      //owner: this.uid

      if (this.state.onlyMe){
        console.log("Only me");
        qp={
          
            owner: this.uid
          
        };
      }

      /*
      if (this.state.searchText != ""){
        console.log("Only me");
        qp={
          "user.first_name": {
            $search : this.state.searchText
          }        
        };
      }
*/

if (this.state.onlyNew){
  qp={
    
      owner: this.uid
    
  };
}




            console.log(qp);
      let uid=this.uid;
      GameMasterRestAPI.getData({
        page: page,
        perPage: perPage,
        service: "bet",
        query_parameter: qp, 

      }).then((data) => {
        console.info(data.data);
        this.setState({
          mapData: data,
          loading: false,
          totalRows: data.total
        });
      });
    };

    componentDidMount() {
      this.getBetData();
    }
    handleFilter = (fieldName, event) => {
      console.info(fieldName);
      console.info(event.target.value);
    };
  
    handleAmountChange = (e) => {
      this.setState({
        amount: e.target.value,
      });
      e.preventDefault();
    };

    handleSearchTextChange = (e) =>{
      this.setState({
        searchText: e.target.value,
      });
      e.preventDefault();      
      this.getBetData();
    }
  
    handleOnlyMe = (e) =>{
      console.log("======");
      console.log(e.target.checked);
      this.setState({
        onlyMe: e.target.checked,
      },()=>{
        this.getBetData();
      });
//      e.preventDefault();      
      
    }

    handleOnlyNew = (e) =>{
      console.log("======");
      console.log(e.target.checked);
      this.setState({
        onlyNew: e.target.checked,
      },()=>{
        this.getBetData();
      });
//      e.preventDefault();      
      
    }
    


    render() {

     // const { userID } = useParams();
     // setUID(userID);
     // this.uid=userID;
//          <input type="text" onChange={this.handleSearchTextChange} />
//          <input type="text" onChange={this.handleSearchTextChange} />
      return (
        <>
          <h1>{/* {JSON.stringify(data) */}</h1>
          <h2>Backed Match</h2>
          <button onClick={this.getBetData} style={{ display: "none" }}>
            Update
          </button>
          
          <input type="checkbox" onChange={this.handleOnlyMe}/> Only Me <br/>
          <input type="checkbox" onChange={this.handleOnlyNew}/> Only New Bet <br/>


          <DataTable
            columns={this.columns}
            data={this.state.mapData.data}
            pagination
            striped
            highlightOnHover
            paginationServer
            paginationTotalRows={this.state.totalRows}
            onChangeRowsPerPage={this.handlePerRowsChange}
            onChangePage={this.handlePageChange}
            sortServer
            onSort={this.handleOnSort}
            paginationPerPage={this.state.perPage}
            paginationRowsPerPageOptions={[25, 50, 100, 150,200,250,500]}
          />
        </>
      );
    }
  }
  

export default BetRecords;

/*

{
  "owner": "nZxNPEns3RI6bTUE",
  "user": {
    "first_name": "火水哥",
    "last_name": "",
    "email": "火水高",
    "password": "$2a$10$To2ohKOVQWi6wiuOqOzMieLHqo62asjJs3ls99KrH4SdDtfEPgfNq",
    "balance": 100,
    "_id": "nZxNPEns3RI6bTUE"
  },
  "match_id": 3,
  "match_info": {
    "data": {
      "id": 3,
      "attributes": {
        "matchName": "🇬🇧英格蘭(H) vs 伊朗(A)🇮🇷  ",
        "Home": 1.36,
        "Away": 11,
        "Draw": 4.6,
        "MaxBet": 100,
        "MatchTime": "2022-11-21T02:00:00.000Z",
        "result": null,
        "createdAt": "2022-11-19T15:18:46.110Z",
        "updatedAt": "2022-11-20T15:02:23.905Z",
        "publishedAt": "2022-11-19T15:18:48.661Z",
        "teamA": {
          "data": {
            "id": 5,
            "attributes": {
              "name": "England 英格蘭",
              "teamid": "England",
              "flag": "🏴󠁧󠁢󠁥󠁮",
              "createdAt": "2022-11-19T10:02:33.679Z",
              "updatedAt": "2022-11-19T10:07:48.047Z"
            }
          }
        },
        "teamB": {
          "data": {
            "id": 6,
            "attributes": {
              "name": "Iran 伊朗",
              "teamid": "Iran",
              "flag": "🇮🇷 ",
              "createdAt": "2022-11-19T10:03:59.997Z",
              "updatedAt": "2022-11-19T10:07:19.153Z"
            }
          }
        }
      }
    },
    "meta": {}
  },
  "status": "new",
  "stake": 100,
  "back": "Home",
  "message": {
    "match": 3,
    "stake": 100,
    "back": "Home",
    "action": "back",
    "owner": "nZxNPEns3RI6bTUE"
  },
  "payout": 1.36,
  "level": "info",
  "createdAt": {
    "$$date": 1668983140609
  },
  "_id": "OBdAddQ687oc1ebl"
}

*/