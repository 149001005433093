
// Connect to a different URL
let restClient = null;

const StrapiClient = require('strapi-client')

const axios = require("axios");

let apiURL = "";
if (process.env.NODE_ENV === "production") {
  apiURL = "https://match24.hk24.club/api";
  if (process.env.NODE_ENV_API && process.env.NODE_ENV_API != "") {
    apiURL = process.env.NODE_ENV_API;
  }
} else {
  apiURL = "http://localhost:1337/api";
}


//app.configure(restClient.axios(axios)); //https://docs.feathersjs.com/api/client/rest.html#feathersjs-rest-client

class MatchMasterRestAPI {
  static instance;

  constructor() {
    if (!MatchMasterRestAPI.instance) {
      this._data = [];


      const app = new StrapiClient(apiURL);
      this.app = app;
      MatchMasterRestAPI.instance = this;
    }

    return MatchMasterRestAPI.instance;
  }


  getData = (query) => {
    let { perPage, page, service, query_parameter, sort } = query;

    query_parameter = query_parameter ? query_parameter : {};
    perPage = perPage ? perPage : 50000;
    page = page ? page : 1;

    let skip = (page - 1) * perPage;
    sort = sort ? sort : {};

    // console.log("===========");
    // console.log({
    //   query: { $limit: perPage, $skip: skip, ...query_parameter },
    // });

    //let res=this.app.get(service, "?populate=*");
    console.info(apiURL+"/"+service+"?populate=*");


/*
    axios.get(apiURL+"/"+service+"?populate=*").then(
      data=>{
        console.info(data);
      }
    ).catch(err => {
      console.info(err);
    });
*/

  let now=new Date();
  now.toISOString();

    console.info(apiURL+"/"+service+"?populate=*");
    return axios.get(apiURL+"/"+service+"?"+"filters[MatchTime][$gt]="+ now.toISOString()+"&populate=*" );
    //return MatchMasterRestAPI.instance.app.get(service, "?populate=*");
    
  };

  getDataByID = ({ id, service }) => {


    return console.info(apiURL+"/"+service+"/"+id+"?populate=*");
    //return this.login().then(() => {
      
      //return MatchMasterRestAPI.instance.app.service(service).get(id);
    //});
  };

  create = (payload) => {
    let { data, service } = payload;
    return this.login().then((done) => {
      return MatchMasterRestAPI.instance.app.service(service).create(data);
    });
  };

  patch = (payload) => {
    let { data, service, id } = payload;
    return this.login().then((done) => {
      return MatchMasterRestAPI.instance.app.service(service).patch(id, data);
    });
  };

  remove = (payload) => {
    let { data, service, id } = payload;
    return this.login().then((done) => {
      return MatchMasterRestAPI.instance.app.service(service).remove(id);
    });
  };


  getVersion = () => {
    try {
      var result=axios.get(apiURL + "/version")
      .catch(()=>{
        return "0.0"
      })
      ;
        return result;
    } catch (err){
      return "0.0";
    }
    
  };

  formatMoney_one = (amount) => {
    return Number.parseFloat(amount).toFixed(2);
  };

  formatMoney = (amount) => {
    return Number.parseFloat(amount).toFixed(2);
  };

  formatWeight = (amount) => {
    return Number.parseFloat(amount).toFixed(3);
  };

  hi = () => {
    return "Hihihi";
  };
}

export default MatchMasterRestAPI = new MatchMasterRestAPI();
