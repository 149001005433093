import {
  faCashRegister,
  faCheck,
  faMinusSquare,
  faPenSquare,
  faQuestionCircle,
  faStore,
  faUnlock,
  faUserClock,
  faMoneyBill,
  faFutbol,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment-timezone";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";

//import OrderListActionButtonBar from "./orderListActionButtonBar";

const GameMasterRestAPI = require("../../service/GameMasterRestAPI.js").default;
const MatchMasterRestAPI =
  require("../../service/MatchMasterRestAPI.js").default;

//https://ellismin.com/2020/05/next-pagination/

const dateTimeFormat = "DD/MM/YY, h:mma";

class Matches extends Component {
  //var mapData;
  //var setMapData;

  constructor(props) {
    super(props);
    this.page = 1;
  }

  backOnMatch = (match_id, back) => {
    let stake = this.state.amount;

    console.log(this.state);


    

    GameMasterRestAPI.create({
      service: "playerAction",
      data: {
        match: match_id,
        stake: stake,
        back: back,
        action: "back",
      },
    }).catch((e) => {
      console.log("Error on create");
      console.log(e);

      if (
        e.message !=
        "before hook for 'create' method returned invalid hook object"
        //hacked for now
      ) {
        confirmAlert({
          title: "⚠ Failed to place back ⚠",
          message: e.message,
          buttons: [
            {
              label: "Yes",
              onClick: () => {},
            },
          ],
        });
      } else {
        confirmAlert({
          title: "Done",
          message: "Back Request Recieved, Good Luck", 
          buttons: [
            {
              label: "Yes",
              onClick: () => {},
            },
          ],
        });
      }
    });
  };

  columns = [
    {
      name: "Match ID",
      selector: "id",
      sortable: true,
      right: true,
    },

    {
      name: "Name",
      selector: "attributes.matchName",
      sortable: true,
      right: true,
      maxWidth: "360px",
    },

    {
      name: "Home Team",
      selector: "row.attributes.teamA.data.attributes.name",
      sortable: true,
      right: true,
      maxWidth: "250px",
      format: (row, index) => {
        return (
          row.attributes.teamA.data.attributes.flag +
          row.attributes.teamA.data.attributes.name
        );
      },
    },

    {
      name: "Away Team",
      selector: "row.attributes.teamB.data.attributes.name",
      sortable: true,
      right: true,
      maxWidth: "250px",
      format: (row, index) => {
        return (
          row.attributes.teamB.data.attributes.flag +
          row.attributes.teamB.data.attributes.name
        );
      },
    },

    {
      name: "Offer - Home / Draw / Away",
      selector: "Home",
      sortable: true,
      right: true,
      maxWidth: "120px",
      format: (row, index) => {
        return (
          row.attributes.Home +
          "/" +
          row.attributes.Draw +
          "/" +
          row.attributes.Away
        );
      },
    },

    {
      name: "MaxBet",
      selector: "attributes.MaxBet",
      sortable: true,
      right: true,
      maxWidth: "30px",
    },

    {
      name: "MatchTime",
      selector: "attributes.MatchTime",
      sortable: true,
      right: true,
      maxWidth: "200px",
    },
    {
      name: "Action",
      selector: "Home",
      format: (row, index) => {
        return (
          <div               onClick={() => this.betDialog(row)}
          >
            <FontAwesomeIcon
              size="lg"
              icon={faMoneyBill}
              style={{}}
            />
            &nbsp; Guess
          </div>
        );
      },
    },
  ];

  state = {
    mapData: {},
    loading: false,
    totalRows: 0,
    perPage: 20,
    sortDirection: 1, // or -1 (1 is ascending, -1 is descending)
    sortSelector: null,
  };

  handleOnSort = (column, sortDirection) => {
    // https://github.com/jbetancur/react-data-table-component#sorting
    const { selector } = column;

    this.setState(
      {
        sortDirection: sortDirection === "asc" ? 1 : -1, // assume there are only asc and desc
        sortSelector: selector,
      },
      () => this.getMatchData()
    );
  };

  handlePageChange = (page) => {
    if (page === -1) {
      page = this.page;
    }
    this.page = page;
    this.getMatchData(page);
  };
  handlePerRowsChange = (perPage, page) => {
    this.setState(
      {
        perPage: perPage,
        currentPage: page,
      },
      () => {
        this.getMatchData(page);
      }
    ); // we don't call setState to avoid double call
  };

  getMatchData = (page = this.page) => {
    console.info(this.state);
    console.info(page);
    const { perPage, sortSelector, sortDirection } = this.state;
    let show_page = page ? page : 1;

    let skip = (show_page - 1) * perPage;
    console.info(skip);

    this.setState({ loading: true });

    console.info("====vvv");

    MatchMasterRestAPI.getData({
      service: "matches",
    }).then((data) => {
      console.info(data.data);
      this.setState({
        mapData: data.data,
        //total: data.meta.pagination.total,
        loading: false,
        //perPage: data.meta.pagination.pageSize,
      });
    });
  };

  componentDidMount() {
    this.getMatchData();
  }
  handleFilter = (fieldName, event) => {
    console.info(fieldName);
    console.info(event.target.value);
  };

  handleAmountChange = (e) => {
    this.setState({
      amount: e.target.value,
    });
    e.preventDefault();
  };

  betDialog = (row) => {
    this.setState({
      amount: row.attributes.MaxBet,
    });

    console.log("BetDialog");
    confirmAlert({
      title: "Match : " + row.attributes.matchName,
      message:
        `Back on the following ?` +
        row.attributes.teamA.data.attributes.name +
        " vs " +
        row.attributes.teamB.data.attributes.name,
      buttons: [
        {
          label: "Home (" + row.attributes.Home + ")",
          onClick: () => {
            GameMasterRestAPI.remove({
              id: row._id,
              service: "orders",
            }).then((result) => {
              this.getMatchData();
            });
          },
        },
        {
          label: "Away (" + row.attributes.Away + ")",
          onClick: () => {},
        },
        {
          label: "Draw (" + row.attributes.Draw + ")",
          onClick: () => {},
        },
      ],
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <center>
              <img src="/HK24.png" width="100px" />
            </center>
            <h1>Match : {row.attributes.matchName}</h1>
            <p>
              Bet on the following ? <br />{" "}
              {row.attributes.teamA.data.attributes.flag}{" "}
              {row.attributes.teamA.data.attributes.name} vs{" "}
              {row.attributes.teamB.data.attributes.flag}{" "}
              {row.attributes.teamB.data.attributes.name}
            </p>
            Amount :{" "}
            <input
              id="amount"
              name="amount"
              defaultValue={row.attributes.MaxBet}
              onChange={this.handleAmountChange}
            />
            <FontAwesomeIcon
              size="lg"
              icon={faFutbol}
              onClick={() => this.betDialog(row)}
              style={{}}
            />
            <br />
            <br />
            <button
              onClick={() => {
                this.backOnMatch(row.id, "Home");
                onClose();
              }}
            >
              Home {row.attributes.teamA.data.attributes.flag} (
              {row.attributes.Home})
            </button>
            <button
              onClick={() => {
                this.backOnMatch(row.id, "Draw");
                onClose();
              }}
            >
              Draw ({row.attributes.Draw})
            </button>
            <button
              onClick={() => {
                this.backOnMatch(row.id, "Away");
                onClose();
              }}
            >
              Away {row.attributes.teamB.data.attributes.flag} (
              {row.attributes.Away})
            </button>
          </div>
        );
      },
    });
  };

  render() {
    return (
      <>
        <h1>{/* {JSON.stringify(data) */}</h1>
        {/* <Container>
          <Row>
            <Col>
              <InputGroup size="sm">
                <FormControl
                  placeholder="Order ID"
                  onChange={this.handleFilter.bind(this, "orderID")}
                />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup size="sm">
                <FormControl placeholder="Client Name" />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup size="sm">
                <FormControl placeholder="Client Mobile" />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup size="sm">
                <FormControl placeholder="Order Description" />
              </InputGroup>
            </Col>
          </Row>
        </Container> */}
        <button onClick={this.getMatchData} style={{ display: "none" }}>
          Update
        </button>
        <DataTable
          columns={this.columns}
          data={this.state.mapData.data}
          pagination
          striped
          highlightOnHover
          paginationServer
          onChangeRowsPerPage={this.handlePerRowsChange}
          onChangePage={this.handlePageChange}
          sortServer
          onSort={this.handleOnSort}
          paginationPerPage={50}
          paginationRowsPerPageOptions={[25, 50, 100, 150]}
        />
      </>
    );
  }
}

//paginationTotalRows={this.state.mapData.total}

export default Matches;
