import React from 'react';
import { useForm } from 'react-hook-form';
//import { useSearchParams } from "react-router-dom";

import {
  Form,
  Button,
  InputGroup,
  FormControl,
  Container,
  Col,
  Row,
  Alert,
} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';


//http://localhost:3000/login?login=123&password=%22$2a$10$YPubRhbKP.Bq5ZdVycrCq.VhDeDOvxKuZdUcgtTMBfkWaAettQA5O%22


const GameMasterRestAPI = require('../service/GameMasterRestAPI.js').default;

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Login = () => {
  const { register, handleSubmit } = useForm();
  const onSubmit = async (data) => {
    setDisableButton(true);

    const { accessToken, message } = await GameMasterRestAPI.login(data);
    if (accessToken) {
      // setToast({ display: true, message: 'Login success', variant: 'success' });
      toast('Login successfully', { type: 'success' });
      setDisableButton(false);
      window.location.pathname = '/Matches';
    } else {
      // setToast({ display: true, message, variant: 'danger' });
      toast(message, { type: 'error' });
    }
  };

  const [disableButton, setDisableButton] = React.useState(false);
  const [login, setLogin] = React.useState(false);
  const [pw, setPW] = React.useState(false);
  let query=useQuery();
  React.useEffect(() => {
    const { expired, logout } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    if (expired) {
      toast('Login Status Expired, please login.', { type: 'error' });
      return;
    }
    if (logout) {
      toast('Logout successfully', { type: 'success' });
      return;
    }

//    const [searchParams] = useSearchParams();



  }, [window.location.search]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <Row>
          <Col>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id={'email_text'}>Login</InputGroup.Text>
                <FormControl
                  placeholder='Login'
                  aria-label='email_text'
                  aria-describedby='email_text'
                  {...register('email', { required: true })}
                  as='input'
                  type='text'
                  defaultValue={query.get('login')}
                />
              </InputGroup.Prepend>
            </InputGroup>
          </Col>
          <Col>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id={'password_text'}>Password</InputGroup.Text>
                <FormControl
                  placeholder='Password'
                  aria-label='password_text'
                  aria-describedby='password_text'
                  {...register('password', { required: true })}
                  as='input'
                  type='password'
                  defaultValue={query.get('password')}
                />
              </InputGroup.Prepend>
            </InputGroup>
          </Col>
          <Col>
            <Button variant='primary' type='submit' disabled={disableButton}>
              Log in
            </Button>
          </Col>
        </Row>
      </Container>
      <ToastContainer></ToastContainer>
    </Form>
  );
};
export default Login;
