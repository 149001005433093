import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Wheel } from 'react-custom-roulette'
import { confirmAlert } from "react-confirm-alert";
const GameMasterRestAPI = require("../../service/GameMasterRestAPI.js").default;

const data = [
/*  { option: '0', style: { backgroundColor: 'green', textColor: 'black' } },
  { option: '1', style: { backgroundColor: 'white' } },
*/
  {option: 	100	},
  {option: 	700, style: { backgroundColor: 'orange', textColor: 'black' }	},
  {option: 	100	},
  {option: 	500	},
  {option: 	100	},
  {option: 	300	},
  {option: 	100	},
  {option: 	700	, style: { backgroundColor: 'orange', textColor: 'black' }},
  {option: 	100	},
  {option: 	500	},
  {option: 	100	},
  {option: 	300	},
  {option: 	100	},
  {option: 	"★ 1000 ★", style: { backgroundColor: 'green', textColor: 'black' }	},
  {option: 	100	},
  {option: 	300	},
  {option: 	200	},
  {option: 	500	},
  {option: 	200	},
  {option: 	700	, style: { backgroundColor: 'orange', textColor: 'black' }},
  {option: 	100	},
  {option: 	500	},
  {option: 	200	},
  {option: 	300	},




]


class Redeem extends Component {
  
  constructor(props) {
    super(props);
    this.state={
      mustSpin: false,
      code: "",
      prizeNumber: 0,
      prizeAmount: 0
    }
  }
  

  handleSpinClick = () => {
    console.log("first");
    GameMasterRestAPI.create({
      service: "redeem",
      data:{
        code: this.state.code
      }
    }).then(result=>{
      console.log(result);
      var newPrizeNumber = result.prizeNumber;
      this.setPrizeNumber(newPrizeNumber);
      this.setPrizeAmount(result.prizeAmount);
      this.setMustSpin(true);
      console.log("Spin");
  
    }).catch(e=>{
      confirmAlert({
        title: "⚠ Failed to Spin ⚠",
        message: e.message,
        buttons: [
          {
            label: "Yes",
            onClick: () => {},
          },
        ],
      });      
    });


  }

  componentDidMount() {
  }

  handleCodeChange = (e) => {
    this.setState({
      code: e.target.value,
    });
    e.preventDefault();
  };

  


  setMustSpin=(val)=>{
    this.setState({mustSpin:val});
  }
  setPrizeNumber=(val)=>{
    this.setState({prizeNumber:val});
  }

  setPrizeAmount=(val)=>{
    this.setState({prizeAmount:val});
  }

  render() {
  
  return (
  <div>
    <center>
    <Wheel
      mustStartSpinning={this.state.mustSpin}
      prizeNumber={this.state.prizeNumber}
      data={data}
      backgroundColors={['#010101', '#f4cf38']}
      textColors={['#ffffff']}
      innerBorderWidth={40}
      onStopSpinning={() => {
        confirmAlert({
          title: "Congratz",
          message: "Extra "+this.state.prizeAmount+" Points would be added to your profile in 15 mins",
          buttons: [
            {
              label: "Yes",
              onClick: () => {},
            },
          ],
        });      
  
        this.setMustSpin(false);
      }}
      spinDuration={0.5}
    />

<br/>
      <input type='text' placeholder="HK24 Prize Code" onChange={this.handleCodeChange}/> 

<button onClick={this.handleSpinClick} disabled={this.state.mustSpin}>Submit</button>
</center>
  </div>);
  }
}




Redeem.propTypes = {};

Redeem.defaultProps = {};

export default Redeem;
