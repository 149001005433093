import {
  faCashRegister,
  faCheck,
  faMinusSquare,
  faPenSquare,
  faQuestionCircle,
  faStore,
  faUnlock,
  faUserClock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment-timezone";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import OrderListActionButtonBar from "./orderListActionButtonBar";

const GameMasterRestAPI = require("../../service/GameMasterRestAPI.js").default;

//https://ellismin.com/2020/05/next-pagination/

const dateTimeFormat = "DD/MM/YY, h:mma";

class OrderList extends Component {
  //var mapData;
  //var setMapData;

  constructor(props) {
    super(props);
    this.page = 1;
  }

  columns = [
    {
      name: "Order ID",
      selector: "id",
      sortable: true,
      right: true,
    },

    {
      name: "Order Amount",
      selector: "order.total",
      sortable: true,
      right: true,
      maxWidth: "30px",
    },

    {
      name: "Status",
      selector: "status",
      sortable: true,
      right: true,
      format: (row, index) => {
        let icon = faUserClock;
        let color = "";
        let text = "";

        switch (row.status) {
          case "order-links-created":
            color = "";
            icon = faUserClock;
            text = "Wait Client go Paypal";
            break;
          case "auth_acquired":
            icon = faStore;
            color = "green";
            text = "Deposit Accepted";
            break;

          case "order_finish_capture":
            icon = faCashRegister;
            text = "Finish-Captured";
            color = "orange";

            break;
          case "order_finish_void":
            icon = faUnlock;
            text = "Finish-Voided";
            color = "gold";
            break;
          case "payment_direct":
            icon = faCheck;
            text = "Finish-Voided";
            color = "green";
            break;

          case "order-ready":
            icon = faUnlock;
            text = "Finish-Voided";
            color = "gold";
            break;

          default:
            icon = faQuestionCircle;
            color = "orange";
            text = row.status;
        }

        return (
          <div>
            {text} <FontAwesomeIcon icon={icon} color={color} />
          </div>
        );
      },
    },

    {
      name: "Client Name",
      selector: "order.field_client",
      sortable: true,
      right: true,
      maxWidth: "50px",
    },
    {
      name: "Client Mobile",
      selector: "order.field_mobile",
      sortable: true,
      right: true,
      maxWidth: "40px",
    },
    {
      name: "Order Description",
      selector: "order.field_details",
      sortable: true,
      right: true,
      order: 1,
      grow: 1,
    },
    {
      name: "Deposit Management Action",
      selector: "order.status",
      sortable: true,
      right: true,
      format: (row, index) => {
        //return this.displayAction(row, index);
        //

        return (
          <div>
            <OrderListActionButtonBar parent={this} data={row} index={index} />
          </div>
        );
      },
    },
    {
      name: "Total Hold Remains (Current / Total)",
      selector: "auth_expiration_time",
      sortable: true,
      right: true,
      format: (row, index) => {
        let timeRemain = 0;
        let timeTotalRemain = -1;
        if (row.auth_expiration_time_current) {
          timeRemain = moment(row.auth_expiration_time_current)
            .diff(moment.now(), "days", true)
            .toFixed(1);
        }

        if (row.auth_expiration_time) {
          timeTotalRemain = moment(row.auth_expiration_time)
            .diff(moment.now(), "days", true)
            .toFixed(1);
        }

        let output = (
          <div>
            {timeRemain} / {timeTotalRemain}
          </div>
        );
        return output;
      },
    },
    {
      name: "Create",
      selector: "createdAt",
      sortable: true,
      format: (row, index) => {
        return moment(row.createdAt)
          .tz("Asia/Hong_Kong")
          .format(dateTimeFormat);
      },
    },
    {
      name: "Update",
      selector: "updateAt",
      sortable: true,
      format: (row, index) => {
        return moment(row.updateAt).tz("Asia/Hong_Kong").format(dateTimeFormat);
      },
    },
    {
      name: "PDF Link",
      selector: "pdfLink",
      // sortable: true,
      format: (row, index) => {
        return (
          (row.pdfLink && (
            <a href={row.pdfLink} target="_blank">
              PDF
            </a>
          )) || <div></div>
        );
      },
    },
    {
      name: "Action",
      selector: "status",
      format: (row, index) => {
        return <div>
            <Link to={`/RentalNoteEdit/${row._id}`}>
              <FontAwesomeIcon
                size="lg"
                icon={faPenSquare}
                style={{ marginLeft: "5px"}}
                color="red"
                
              />
            </Link>
        </div>;
      },
    },
  ];
  /*

            <FontAwesomeIcon
              size="lg"
              icon={faMinusSquare}
              color="red"
              onClick={() => this.deleteRentalNote(row)}
              style={{ display: "none" }}
            />



 */

  state = {
    mapData: {},
    loading: false,
    totalRows: 0,
    perPage: 20,
    sortDirection: 1, // or -1 (1 is ascending, -1 is descending)
    sortSelector: null,
  };

  handleOnSort = (column, sortDirection) => {
    // https://github.com/jbetancur/react-data-table-component#sorting
    const { selector } = column;

    this.setState(
      {
        sortDirection: sortDirection === "asc" ? 1 : -1, // assume there are only asc and desc
        sortSelector: selector,
      },
      () => this.getMatchData()
    );
  };

  handlePageChange = (page) => {
    if (page === -1) {
      page = this.page;
    }
    this.page = page;
    this.getMatchData(page);
  };
  handlePerRowsChange = (perPage, page) => {
    this.setState(
      {
        perPage: perPage,
        currentPage: page,
      },
      () => {
        this.getMatchData(page);
      }
    ); // we don't call setState to avoid double call
  };

  getMatchData = (page = this.page) => {
    console.info(this.state);
    console.info(page);
    const { perPage, sortSelector, sortDirection } = this.state;
    let show_page = page ? page : 1;

    let skip = (show_page - 1) * perPage;
    console.info(skip);

    this.setState({ loading: true });

    GameMasterRestAPI.getData({
      page: page,
      perPage: perPage,
      service: "orders",
      // https://github.com/gaearon/feathers-docs/blob/master/databases/pagination.md#sort
      sort: sortSelector ? { [sortSelector]: sortDirection } : null,
    }).then((data) => {
      console.info(data);
      this.setState({
        mapData: data,
        totalRows: data.total,
        loading: false,
        perPage: perPage,
      });
    });

    //    console.info(data);
  };

  componentDidMount() {
    this.getMatchData();
  }
  handleFilter = (fieldName, event) => {
    console.info(fieldName);
    console.info(event.target.value);
  };

  deleteRentalNote = (row) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: `Are you sure to delete Order Note ${row._id}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            GameMasterRestAPI.remove({
              id: row._id,
              service: "orders",
            }).then((result) => {
              this.getMatchData();
            });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  render() {
    return (
      <>
        <h1>
          {this.state.mapData.total} Orders
          {/* {JSON.stringify(data) */}
        </h1>
        {/* <Container>
          <Row>
            <Col>
              <InputGroup size="sm">
                <FormControl
                  placeholder="Order ID"
                  onChange={this.handleFilter.bind(this, "orderID")}
                />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup size="sm">
                <FormControl placeholder="Client Name" />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup size="sm">
                <FormControl placeholder="Client Mobile" />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup size="sm">
                <FormControl placeholder="Order Description" />
              </InputGroup>
            </Col>
          </Row>
        </Container> */}
        <button onClick={this.getMatchData}>Update</button>

        <DataTable
          columns={this.columns}
          data={this.state.mapData.data}
          pagination
          striped
          highlightOnHover
          paginationTotalRows={this.state.mapData.total}
          paginationServer
          onChangeRowsPerPage={this.handlePerRowsChange}
          onChangePage={this.handlePageChange}
          sortServer
          onSort={this.handleOnSort}
        />
      </>
    );
  }
}

export default OrderList;
