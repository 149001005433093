import React from 'react';
import PropTypes from 'prop-types';


const Profile = () => (
  <div>
    Profile Component
  </div>
);

Profile.propTypes = {};

Profile.defaultProps = {};

export default Profile;
